/**
 * @fileoverview Entire frontend-routing takes place.
 *
 * @author Jai Prakash Ellapu
 * @date 15th March, 2024
 *
 * @reviewer Avinash Krishna Peesapati
 * @date 14th May, 2024
 *
 * Setting up routing for different paths.
 *
 * @copyright Copyright (C) 2024 QualiZeal India LLP
 *
 * @license Closed - QualiZeal India LLP - Intellectual Property
 *
 * @todo - Protected routing to dashboard and admin panel; Password update; and unique routings for each user
 */

import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GuardedUserLogin, GuardedUserRoute } from "./services/authGuard";
import LoginForm from './components/LoginForm';
const PasswordResetForm = React.lazy(() => import('./components/PasswordResetForm'));
const ForgotPasswordForm = React.lazy(() => import('./components/ForgotPasswordForm'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const ScrutinizeRenderer = React.lazy(() => import('./components/ScrutinizeRenderer'));
const Invest = React.lazy(() => import('./components/Invest'));
const NotFoundPage = React.lazy(() => import('./components/NotFoundPage'));
const SessionExpirePage = React.lazy(() => import('./components/SessionExpirePage'));
const JiraConfiguration = React.lazy(() => import('./components/JiraConfiguration'));
const HelpPage = React.lazy(() => import('./components/HelpPage'));
const MitigatePro = React.lazy(() => import('./components/MitigatePro'));
const TestScape = React.lazy(() => import('./components/TestScapeRenderer'));
const Usecase2Testcase = React.lazy(() => import('./components/Usecase2TestcaseRenderer'));
const DataGenie = React.lazy(()  => import('./components/DataGenie'));
const QuesT = React.lazy(() => import('./components/QuesT'));
const GenTestRenderer = React.lazy(()=> import('./components/GenTestRenderer'));

function App() {
  return (
    <Router>
      {/* <Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}> */}
      <Routes>
        {/****** After Login Routes *******/}
        <Route element={<GuardedUserRoute />}>
          <Route path="/dashboard" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading Dashboard...<br />Quality Intelligence starts here!</div>}><Dashboard /></Suspense>}>
            <Route
              path="/dashboard/scrutinizeService"
              element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><ScrutinizeRenderer /></Suspense>}
            />
            {/* <Route
              path="/dashboard/investigateService"
              element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><InvestigateRenderer /></Suspense>}
            /> */}
            <Route
            path="/dashboard/genTest"
            element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><GenTestRenderer/></Suspense>}
            />
            <Route path="/dashboard/jira-config" element={<Suspense fallback={<div>Fetching your Jira details... <br />Hang tight while we gather your information. </div>}><JiraConfiguration /></Suspense>} />
            <Route path="/dashboard/help" element={<Suspense fallback={<div>Loading details...<br />Your complete guide to our services will appear shortly!</div>}><HelpPage /></Suspense>} />
            {/* <Route path="/dashboard/mitigate-pro" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><MitigatePro /></Suspense>} /> */}
            <Route path="/dashboard/testscape" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><TestScape /></Suspense>} />
            <Route path="/dashboard/invest" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><Invest /></Suspense>} />
            {/* <Route path="/dashboard/data-genie" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><DataGenie /></Suspense>} /> */}
            {/* <Route path="/dashboard/quest" element={<Suspense fallback={<div>Retrieving knowledge sources...<br />Loading examples...<br />Quality Intelligence starts here!</div>}><QuesT /></Suspense>} /> */}

            

          </Route>
          <Route path="*" element={<Suspense fallback={<div>Hang tight... <br />We're searching for the page you need!</div>}><NotFoundPage /></Suspense>} />
        </Route>

        {/* Before Login Routes */}
        <Route element={<GuardedUserLogin />}>
          <Route path="/" element={<LoginForm />} />
          <Route path="/forgot-password" element={<Suspense fallback={<div>Retrieving Reset Options...</div>}><ForgotPasswordForm /></Suspense>} />
          <Route path="/reset-password" element={<Suspense fallback={<div>Hang tight... <br />We're unlocking the gateway to your new password.</div>}><PasswordResetForm /></Suspense>} />
        </Route>
        <Route path='/session-expired' element={<Suspense fallback={<div>Redirecting...</div>}><SessionExpirePage /></Suspense>}/>
      </Routes>
      {/*  */}
    </Router>
  );
}

export default App;
