/**
 * @fileoverview Capturing bug report details and submitting data to an API endpoint.
 *
 * @author Madhu Pavan
 * @date 10th May, 2024
 * 
 * @reviewer Jai Prakash Ellapu
 * @date 13th May, 2024
 *
 * Bug-report details are taken as input from the user and delivered to the server.
 *
 * @copyright Copyright (C) 2024 QualiZeal India LLP
 *
 * @license Closed - QualiZeal India LLP - Intellectual Property
 *
 * @todo Input validations
 */

import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { sessionValidation } from './webServices';

const GuardedUserRoute = () => {
  const [auth, setAuth] = useState(null); // Initialize with null to handle the loading state
  const [sessionExpiration, setSessionExpiration] = useState(null);

  useEffect(() => {
    const validateSession = async () => {
      const validationResult = await sessionValidation();
      setAuth(validationResult.data.isValidSession);
      if (validationResult.data.sessionExpiration) {
        setSessionExpiration(validationResult.data.sessionExpiration);
      }
    };

    validateSession();
  }, []);

  if (auth === null) {
    // You can return a loading spinner or some placeholder while waiting for the validation
    return <div>Loading...</div>;
  }

  return auth ? <Outlet context={{sessionExpiration}} /> : <Navigate to="/" replace={true} />;
};

const GuardedUserLogin = () => {
  const [auth, setAuth] = useState(null);
  const [sessionExpiration, setSessionExpiration] = useState(null);
  useEffect(() => {
    const validateSession = async () => {
      const validationResult = await sessionValidation();
      setAuth(validationResult.data.isValidSession);
      if (validationResult.data.sessionExpiration) {
        setSessionExpiration(validationResult.data.sessionExpiration);
      }
    };

    validateSession();
  }, []);

  if (auth === null) {
    // You can return a loading spinner or some placeholder while waiting for the validation
    return <div>Loading...</div>;
  }

  return !auth ? <Outlet sessionExpiration={sessionExpiration} /> : <Navigate to="/dashboard" replace={true} sessionExpiration={sessionExpiration} />;
};

export { GuardedUserRoute, GuardedUserLogin };
