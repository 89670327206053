import axios from "axios";
import baseUrl from "./baseUrl";

axios.defaults.withCredentials = true;


/**** Login ****/
export const login = (data) => {
  const loginUserURL = baseUrl.base_url + "/login"
  const response = axios.post(loginUserURL, data);
  localStorage.setItem('email', data.username);
  return response;
};

/**** Forgot password ****/
export const forgotPassword = (data) => {
  const resetPasswordLink = `${baseUrl.frontendUrl}/reset-password`;
  const apiUrl = `${baseUrl.base_url}/forgot-password`;
  const response = axios.post(apiUrl, { username: data.username, resetPasswordLink });
  return response;
}

/**** Reset password ****/
export const resetPassword = (data) => {
  const apiUrl = `${baseUrl.base_url}/reset-password`;
  const response = axios.post(apiUrl, data);

  return response;
}


export const fetchDisplayName = async (data) => {
  const fetchNameURL = `${baseUrl.base_url}/fetch-display-name`; // Ensure baseUrl.base_url is defined and correct
  try {
    const response = await axios.post(fetchNameURL, data);
    return response; // Return the full response object for detailed handling
  } catch (error) {
    console.error("Error fetching display name from API:", error);
    throw error; // Re-throw error for handling in the caller
  }
};

/***** LOG OUT *****/
export const logout = () => {
  localStorage.clear();
  return axios.get(`${baseUrl.base_url}/logout`);
};

/***** Admin Login *****/
export const adminLogin = (data) => {
  return axios.post(`${baseUrl.base_url}/admin/login`, data);
};

/***** Get All users *****/
export const getAllUsers = () => {
  return axios.post(`${baseUrl.base_url}/admin/getUsers`);
}

/*****CREATE USERS BY ADMIN *****/
export const createUser = async (data) => {
  const addUserApi = baseUrl.base_url + "/create/user";
  const reset_password_link = `${baseUrl.frontendUrl}/reset-password`;
  const { username, password, displayName } = data;

  const response = await axios.post(addUserApi, { username, password, displayName, reset_password_link });
  return response;
};

/***** Delete Users *****/
export const deleteUser = (data) => {
  const deleteUserApi = `${baseUrl.base_url}/delete/user/${data}`;
  return axios.delete(deleteUserApi);
}

/***** Edit Users *****/
export const editUser = (data) => {
  return axios.put(`${baseUrl.base_url}/edit/user/${data.id}`, {
    display_name: data.displayName
  });
}

export const sessionValidation = () => {
  const validationUrl = `${baseUrl.base_url}/sessionPing`;
  const validationResult = axios.get(validationUrl);
  return validationResult;
}

/***** Scrutinize api for bug report enhancement *****/
export const scrutinize = (data) => {
  const apiUrl = `${baseUrl.base_url}/QEBotGateway/scrutinizeService`;
  const enhancedReport = axios.post(apiUrl, data);

  return enhancedReport;
}

/***** Investigate api *****/
export const investigate = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/investigateService";
  const response = axios.post(apiUrl, data);
  return response;
}

export const refinedUserstory = () => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/refineUserStory";
  const response = axios.post(apiUrl);
  return response;
}

export const userStoryToTestScenarios = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/userStoryToTestScenarios";
  const response = axios.post(apiUrl, data);
  return response;
}

/***** Invest api *****/
export const invest = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/investService";
  const response = axios.post(apiUrl, data);
  return response;
}

export const refinedUserstories = () => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/refineUserStories";
  const response = axios.post(apiUrl);
  return response;
}

/**** GenTest api ****/
export const userStoryToUseCase = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/genTestUseCase";
  const response = axios.post(apiUrl, data);
  return response;
}

export const businessProcessGuide = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/businessProcessGuide";
  const response = axios.post(apiUrl, data);
  return response;
}

export const genTestCases = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/genTestCases";
  const response = axios.post(apiUrl, data);
  return response;
}

/**** UseCase2TestCase api ****/
export const generateTestCases = (data) => {
  const apiUrl = baseUrl.base_url + "/QEBotGateway/useCase2TestCaseService";
  const response = axios.post(apiUrl, data);
  return response;
}

/**** Jira calls ****/
export const getJiraConfig = (userEmail) => {
  const apiUrl = baseUrl.base_url + `/get-jira-config/${userEmail}`;
  const response = axios.get(apiUrl);
  return response;
}

export const saveJiraConfig = (data) => {
  const apiUrl = baseUrl.base_url + "/save-jira-config";
  const response = axios.post(apiUrl, data);
  return response;
}

export const createCustomFields = () => {
  const apiUrl = baseUrl.base_url + "/create-custom-fields";
  const response = axios.post(apiUrl);
  return response;
}

export const createJiraIssueApiCall = (data) => {
  const apiUrl = baseUrl.base_url + "/create-jira-issue";
  const response = axios.post(apiUrl, data);
  return response;
}

export const fetchJiraIssuesApiCall = (projectId) => {
  const apiUrl = baseUrl.base_url + `/jira-issues/${projectId}`; // Match the backend route
  const response = axios.get(apiUrl);
  return response;
};

export const importJiraIssueApiCall = (projectId, issueId) => {
  const apiUrl = baseUrl.base_url + `/import-jira-issue`;
  return axios.post(apiUrl, {projectId,issueId});
}


/***** Logger call *****/
export const logger = (level, message) => {
  const loggerApiUrl = baseUrl.loggerUrl;
  try {
    axios.post(loggerApiUrl, { level, message });
  } catch (error) {
    console.error('Error logging message:', error.message);
  }
}

 /***** TestScape ******/
 export const testScape = async (data) => {
  try {
    const apiUrl = baseUrl.base_url + "/QEBotGateway/testScape";
    const response = await axios.post(apiUrl, { userInput: data }); // Ensure data is wrapped in an object
    return response.data; // Return the response data
  } catch (error) {
    // console.error('Error occurred while calling testScape:', error);
    throw error; // Handle errors appropriately
  }
}

export const riskAssessment = async (data) => {
  const apiUrl = `${baseUrl.base_url}/QEBotGateway/riskAssessment`;
  const riskAssessResponse = await axios.get(apiUrl);
  return riskAssessResponse;
};

export const contingencySteps = async(data)=>{
  const apiUrl = `${baseUrl.base_url}/QEBotGateway/contingencySteps`;
  const riskAssessResponse = await axios.get(apiUrl);
  return riskAssessResponse;
}
