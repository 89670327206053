import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const QzButtonStyles = styled(Button)((
    { theme }) => ({
        color: "white",
        padding: "12px 24px",
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderRadius: "8px",
        background: "linear-gradient(45deg, #0095FF 30%, #F73F5B 90%)",

        '&:hover': {
            background: "linear-gradient(45deg, #F73F5B 30%, #0095FF 90%)",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        },
        '&:disabled': {
            background: "linear-gradient(45deg, #bdbdbd 30%, #9e9e9e 90%)",
            color: "#ffffff",
        },
    }));

const QzButton = (props) => {
    const { startIcon, ...otherProps } = props;
    return <QzButtonStyles {...otherProps} type={props.type || "submit"} startIcon={startIcon ? React.cloneElement(startIcon, { style: { fontSize: '30px' } }) : null} />;
};


export default QzButton;
