/**
 * @fileoverview User-login interface
 *
 * @author Jai Prakash Ellapu
 * @date 14th March, 2024
 *
 * @reviewer Vineetha Garimella
 * @date 14th May, 2024
 *
 * Allowing user to enter credentials and enter into the application.
 *
 * @copyright Copyright (C) 2024 QualiZeal India LLP
 *
 * @license Closed - QualiZeal India LLP - Intellectual Property
 *
 * @todo - UI enhancement and Forgot Password
 */

import React, { useState } from "react";
import {
  TextField,
  Typography,
  Container,
  Box,
  Link,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { login } from "../services/webServices";
import { ReactComponent as QEBotLogo } from "../images/QEBot_logo.svg";
import QzButton from "./QzButton";
import base_url from "../services/baseUrl";

const isEdgeBrowser = () => {
  const userAgent = navigator.userAgent;
  return userAgent.includes("Edg") || userAgent.includes("Edge");
};

function LoginForm() {
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleLogin = async (e) => {
    e.preventDefault();

    const username = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    const errors = {};
    if (!username.trim()) {
      errors["username"] = "Please enter email address.";
    }
    if (!password.trim()) {
      errors["password"] = "Please enter password.";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      // console.log(formErrors);
      return;
    }

    try {
      const response = await login({ username, password });

      setError("Login successful.");
      setSeverity("success");
      navigate("/dashboard/genTest");
      // Redirect to dashboard here
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Session expired. Please log in again.");
      } else {
        setError("Login Failed. Please enter valid credentials.");
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const hideEye = isEdgeBrowser();
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        overflow: "clip",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{ mt: 1, textAlign: "right" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ width: "180px", height: "180px", padding: "0px" }}
            >
              <QEBotLogo />
            </IconButton>
            <Typography
              component="h1"
              variant="h5"
              sx={{ textAlign: "center" }}
            >
        {base_url.customer ? (
                <>
                  QEBot.ai™ - Your Partner for Quality Intelligence.
                  <br />
                  (Secure Instance for {base_url.customer})
                </>
              ) : (
                "QEBot.ai™ - Your Partner for Quality Intelligence"
              )}
            </Typography>
          </Box>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!formErrors.username}
            helperText={formErrors.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type={showPassword ? "text" : "password"}
            id="password"
            label="Password"
            error={!!formErrors.password}
            helperText={formErrors.password}
            InputProps={{
              endAdornment: !hideEye && (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      showPassword
                        ? "Click to hide password"
                        : "Click to show password"
                    }
                    placement="top" // Position of the tooltip
                    arrow // Add an arrow to the tooltip
                  >
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                      size="small"
                      style={{ color: "#0095ff" }}
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <QzButton fullWidth>Login</QzButton>

          <Typography color={severity === "error" ? "error" : "primary"}>
            {error}
          </Typography>
          <Link href="/forgot-password" variant="body2" sx={{ mt: 1 }}>
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginForm;
