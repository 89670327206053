const base_url = {
  /***** LOCAL DEVELOPMENT ******/
  // base_url: "http://localhost:5000",
  // frontendUrl: "http://localhost:3000",
  // customer: 'Local'

  /**** DEV URLS ****/
  // base_url: "https://dev.qebot.ai/api",
  // frontendUrl: "https://dev.qebot.ai",

  /***** PRODUCTION URLS ******/
  base_url: "https://qebot.ai/api",
  frontendUrl: "https://qebot.ai",

  /**** NCL URLS ****/
  // base_url: "https://ncl.qebot.ai/api",
  // frontendUrl: "https://ncl.qebot.ai",
  // customer: 'NCL'

  /**** AAA URLS ****/
  // base_url: "https://aaa.qebot.ai/api",
  // frontendUrl: "https://aaa.qebot.ai",
  // customer: 'AAA'

  /**** Talkshoplive URLS ****/
  // base_url: "https://talkshoplive.qebot.ai/api",
  // frontendUrl: "https://talkshoplive.qebot.ai",
  // customer: 'Talkshoplive'

  /**** EDH URLS ****/
  // base_url: "https://edh.qebot.ai/api",
  // frontendUrl: "https://edh.qebot.ai",
  // customer: 'EDH'

  /**** Test URLS ****/
  // base_url: "https://testing.qebot.ai/api",
  // frontendUrl: "https://testing.qebot.ai",

    /**** Eshopbox URLS ****/
  // base_url: "https://eshopbox.qebot.ai/api",
  // frontendUrl: "https://eshopbox.qebot.ai",
  // customer: 'EShopBox'

    /**** Lightpathfiber URLS ****/
  // base_url: "https://lightpathfiber.qebot.ai/api",
  // frontendUrl: "https://lightpathfiber.qebot.ai",
  // customer: 'Lightpathfiber'

    /**** HealthTrackRX URLS ****/
  // base_url: "https://healthtrackrx.qebot.ai/api",
  // frontendUrl: "https://healthtrackrx.qebot.ai",
  // customer: 'HealthTrackRX'

    /**** Hyniva URLS ****/
  // base_url: "https://hyniva.qebot.ai/api",
  // frontendUrl: "https://hyniva.qebot.ai",
  // customer: 'Hyniva'

    /**** ASTM URLS ****/
  // base_url: "https://astm.qebot.ai/api",
  // frontendUrl: "https://astm.qebot.ai",
  // customer: 'ASTM'
  
};
export default base_url;
