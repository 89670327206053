import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// function sendMetricsToServer(metric) {
//     fetch('http://localhost:5000/metrics', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(metric)
//     }).then(response => {
//         if (response.ok) {
//             console.log('Metric sent to server');
//         } else {
//             console.error('Error sending metric to server');
//         }
//     }).catch(error => {
//         console.error('Error sending metric to server', error);
//     });
// }

// reportWebVitals(sendMetricsToServer);

root.render(
    <App />
);
